import Typography from "typography"
import Theme from "typography-theme-grand-view"

Theme.overrideThemeStyles = (_props, _options, styles) => {
  return {
    "a:hover,a:active": {
      color: styles.a.color,
    },
  }
}
Theme.bodyFontFamily = ["Merriweather", "serif"]
Theme.googleFonts = [
  {
    name: "Montserrat",
    styles: ["700"],
  },
  {
    name: "Merriweather",
    styles: ["400", "400i", "700", "700i"],
  },
]

const typography = new Typography(Theme)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
